#root {
    height: 100%;
    position: relative;
}

#popup {
    z-index: -1;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

#popup.active {
    z-index: 100;
}
